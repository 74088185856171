import Calculator from "../components/Calculator";

import React from 'react'

function Conversion() {
  return (
	<div>
		<Calculator />
	</div>
  )
}

export default Conversion 